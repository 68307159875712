import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { VictoryChart } from "victory-chart"
import { VictoryLine } from "victory-line"
import { VictoryAxis } from "victory-axis"
import { VictoryScatter } from "victory-scatter"
import { VictoryLabel } from "victory-label"
import { VictoryVoronoiContainer } from "victory-voronoi-container"
import CursorIconGroup from "../../assets/cursorIconGroup"
import FunnelIcon from "../../assets/funnel"
import InfoIcon from "../../assets/infoIcon"
import PeopleIcon from "../../assets/peopleIcon"
import WigglyLines from "../../assets/wigglyLine"
import ColorSelector from "../../components/ColorSelector"
import Footer from "../../components/Footer"
import LogoHeader from "../../components/LogoHeader"
import Popover from "../../components/Popover"
import "./stats.css"
import VisitorDistribution from "../../components/VisitorDistribution"
import axios from "axios"
import CountdownTimer from "../../components/CountdownTimer"
import { normalizeDataInPercent } from "../../utils/normaliseToPercentValues"
import { getPercent } from "../../utils/getPercent"
import FloatingBannerContainer from "../../components/FloatingBannerContainer"
import { VictoryTooltip } from "victory-tooltip"
import LineGraph from "../../assets/LineGraph"
import { sendAmplitudeData } from "../../utils/amplitude"
import { AmplitudeEvents } from "../../types/amplitudeEvents"
import { isBefore28thOctober } from "../../utils/isBefore28thOctober"
import Seo from "../../components/seo"
import PHBadgeScribble from "../../assets/phBadgeScribble"
import animation from "../../assets/lottieCheck.json"
import lottie from "lottie-web"
import { utmCookieRequest } from "../../utils/handleUTFTracking"

import jsonData from "../../data/ph-launch-tracker.json"

const funnelStaticData = [
  {
    title: "Visited Website",
    funnelWidth: undefined,
    funnelColor: "#FDF2EA",
    popoverText: "# of unique visitors",
    count: null,
  },
  {
    title: "Created Socials",
    funnelWidth: undefined,
    funnelColor: "#FBE5D4",
    popoverText: "# of socials created",
    count: null,
  },
  {
    title: "Started Socials",
    funnelWidth: undefined,
    funnelColor: "#F4B07F",
    popoverText: "# of socials started",
    count: null,
  },
  {
    title: "Played Mixers",
    funnelWidth: undefined,
    funnelColor: "#ED7B2A",
    popoverText: "# of socials with mixers played",
    count: null,
  },
  {
    title: "Loved it",
    funnelWidth: undefined,
    funnelColor: "#D96716",
    popoverText: "# of social with 5-star ratings",
    count: null,
  },
]

const StatsPage: FunctionComponent = () => {
  const [socialsCreated, setSocialsCreated] = useState([])
  const [totalVisitors, setTotalVisitors] = useState([])
  const serverData = getData()
  const { response } = serverData
  const [funnelData, setFunnelData] = useState([])
  const days = [
    "friday",
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
  ]
  let lottieContainerVisitors = useRef<HTMLDivElement>()
  let lottieContainerSocials = useRef<HTMLDivElement>()

  useEffect(() => {
    let normailzedSocialsCreated = response.socialsCreatedHistory

    const normalizedTotalVisitors = response.uniqueVisitorsHistory

    const normalizedFunnelData = response.funnelData.map((val, ind) => {
      const currentFunnelData = { ...funnelStaticData[ind] }
      currentFunnelData.count = val

      return currentFunnelData
    })

    setFunnelData(normalizedFunnelData)

    setTotalVisitors(normalizedTotalVisitors)

    setSocialsCreated(normailzedSocialsCreated)

    const extraParams: { "ref-url"?: string } = {}

    if (document && document.referrer) {
      extraParams["ref-url"] = document.referrer
    }
    sendAmplitudeData(AmplitudeEvents.TRACKER_PAGE_VISITED, extraParams)

    // Load external script for shout-out
    const loadScript = function (src) {
      const tag = document.createElement("script")
      tag.async = false
      tag.src = src
      const body = document.getElementsByTagName("body")[0]
      body.appendChild(tag)
    }

    loadScript("https://embed.shoutout.so/embed.js")
  }, [])

  useEffect(() => {
    const checkAnimation = lottie.loadAnimation({
      container: lottieContainerVisitors.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation,
    })
    return () => checkAnimation.destroy() // optional clean up for unmounting
  }, [])

  useEffect(() => {
    const checkAnimation = lottie.loadAnimation({
      container: lottieContainerSocials.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation,
    })
    return () => checkAnimation.destroy() // optional clean up for unmounting
  }, [])

  useEffect(() => {
    utmCookieRequest()
  }, [])

  return (
    <>
      <Seo
        title="Product Hunt Launch Day Tracker"
        description="See our live metrics like unique visitors, socials started, etc. for our Product Hunt launch day."
      />
      <div className="hero-section purple">
        <LogoHeader isLink headerData={days} logo="thursdayLogo" />

        <div className="h1Text hero-text">
          PH launch day&nbsp;
          <span className="text-with-underline">
            <span className="underlineAnimation-activate-onHover">tracker</span>

            <div className="underlineAnimation-container">
              <div className="wigglyLines-container">
                <WigglyLines color="var(--theme-white)" />
              </div>
            </div>
          </span>
        </div>

        <div className="hero-subText h3Text">
          We launched on Product Hunt on October 28, 2021, and were #2 Product
          of the day!
        </div>

        <div className="ph-badge">
          <a
            href="https://www.producthunt.com/posts/thursday?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-thursday"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=317530&theme=light&period=daily"
              alt="Thursday - Where remote teams do their socials, no sign up required | Product Hunt"
              style={{ width: "250px", height: "54px" }}
              width="250"
              height="54"
            />
          </a>

          {/* <div className="phBadge-scribble">
            <PHBadgeScribble />
          </div> */}
        </div>

        <div className="h2Text hero-sec2-header">Actuals / Goals</div>

        <div className="hero-sec2-statsCards">
          <div className="hero-sec2-statsCard1">
            {response.uniqueVisitors >= 1000 && (
              <div
                className="lottie-check-container"
                ref={lottieContainerVisitors}
              ></div>
            )}
            <PeopleIcon size="75" color="var(--theme-purple)" />
            <div
              className={`h2Text numbers ${
                response.uniqueVisitors >= 1000 && "goal-achieved"
              }`}
            >
              {response.uniqueVisitors}
              <span className="outOf h3Text">/1000</span>
            </div>
            <div className="stats-label h3Text">Unique Visitors</div>
          </div>
          <div className="hero-sec2-statsCard2">
            {response?.funnelData?.[2] >= 100 && (
              <div
                className="lottie-check-container"
                ref={lottieContainerSocials}
              ></div>
            )}
            <CursorIconGroup size="75" color="var(--theme-purple)" />
            <div
              className={`h2Text numbers ${
                response?.funnelData?.[2] >= 100 && "goal-achieved"
              }`}
            >
              {response?.funnelData?.[2]}
              <span className="outOf h3Text">/100</span>
            </div>
            <div className="stats-label h3Text">Socials Started</div>
          </div>
        </div>
      </div>

      <div className="socials-created-section">
        <div className="h2Text">Visitors and Socials Started</div>

        <div className="line-graph-container">
          <VictoryChart
            theme={{
              line: {
                style: {
                  labels: {
                    fontSize: 10,
                  },
                },
              },
            }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => `Socials: ${datum.y}`}
                labelComponent={
                  <VictoryTooltip
                    constrainToVisibleArea
                    flyoutPadding={{ left: 8, right: 8 }}
                    flyoutHeight={20}
                    // dy={2}
                    centerOffset={{ y: -5 }}
                    flyoutStyle={{
                      stroke: "#2F373D",
                      fill: "#2F373D",
                      padding: "8px",
                      width: "200px",
                    }}
                    style={{
                      fontFamily: "LabilB",
                      fontSize: "10px",
                      fontWeight: "600",
                      lineHeight: "16px",
                      fill: "#fff",
                      margin: "8px",
                    }}
                  />
                }
                voronoiBlacklist={["scatter1"]}
              />
            }
          >
            <LineGraph
              indiaLunch={socialsCreated?.[socialsCreated?.length - 1]?.x >= 90}
              nyWake={socialsCreated?.[socialsCreated?.length - 1]?.x >= 300}
              calWake={socialsCreated?.[socialsCreated?.length - 1]?.x >= 480}
              gerEnd={socialsCreated?.[socialsCreated?.length - 1]?.x >= 630}
              ausWake={socialsCreated?.[socialsCreated?.length - 1]?.x >= 840}
              indiaWake={
                socialsCreated?.[socialsCreated?.length - 1]?.x >= 1200
              }
            />
            <VictoryAxis
              style={{
                grid: {
                  display: "none",
                },
                tickLabels: {
                  fontSize: 8,
                  fontWeight: "700",
                  fontFamily: "Space Mono, monospace",
                  fill: "#475766",
                  padding: 5,
                },
                axis: {
                  strokeWidth: "6px",
                  stroke: "#2F373Db3",
                  display: "none",
                },
              }}
              tickValues={[240, 480, 720, 960, 1200, 1440]}
              tickFormat={val => `${val / 60}h`}
            />

            <VictoryLine
              name="line1"
              labels={val => {
                return +val.index === socialsCreated.length - 1
                  ? `${val.datum.y}\nSocials Started`
                  : null
              }}
              data={socialsCreated}
              interpolation="basis"
              style={{
                data: {
                  strokeWidth: "3px",
                  stroke: "#1EB482",
                },
                labels: {
                  fontSize: 8,
                  fontWeight: "700",
                  fontFamily: "Space Mono, monospace",
                  fill: "#1EB482",
                  padding: 5,
                  transform:
                    socialsCreated?.[socialsCreated?.length - 1]?.x >= 1200
                      ? "translate(-40, 5)"
                      : "translate(40, 5)",
                },
              }}
            />
            <VictoryScatter
              style={{ data: { fill: "#1EB482" } }}
              data={[
                socialsCreated?.[socialsCreated?.length - 1] || {
                  x: 0,
                  y: 0,
                },
              ]}
              name="scatter1"
            />

            <VictoryAxis
              domain={[
                0,
                socialsCreated?.[socialsCreated?.length - 1]?.y + 350,
              ]}
              style={{
                ticks: { padding: -35 },
                tickLabels: {
                  fontSize: 8,
                  fontWeight: "700",
                  fontFamily: "Space Mono, monospace",
                  fill: "#1EB482",
                },
                axis: {
                  strokeWidth: "6px",
                  stroke: "#1EB482",
                  strokeLinejoin: "round",
                  display: "none",
                },
                grid: {
                  display: "none",
                },
              }}
              dependentAxis
              offsetX={400}
            />
          </VictoryChart>

          <div className="overlayed-line">
            <VictoryChart
              theme={{
                line: {
                  style: {
                    labels: {
                      fontSize: 10,
                    },
                  },
                },
              }}
              containerComponent={
                <VictoryVoronoiContainer
                  labels={({ datum }) => `Visitors: ${datum.y}`}
                  radius={10}
                  labelComponent={
                    <VictoryTooltip
                      // constrainToVisibleArea
                      flyoutPadding={{ left: 6, right: 6 }}
                      flyoutHeight={16}
                      pointerLength={3}
                      pointerWidth={6}
                      // dy={2}
                      // centerOffset={{ y: -5 }}
                      flyoutStyle={{
                        stroke: "#2F373D",
                        fill: "#2F373D",
                      }}
                      style={{
                        fontFamily: "LabilM",
                        fontSize: "7.5px",
                        fontWeight: "medium",
                        lineHeight: "160%",
                        fill: "#fff",
                        boxShadow: "0 2px 4px #0001",
                      }}
                    />
                  }
                  voronoiBlacklist={["scatter2"]}
                />
              }
            >
              <VictoryAxis
                tickValues={[240, 480, 720, 960, 1200, 1440]}
                tickFormat={val => `${val / 60}h`}
                style={{
                  axis: {
                    display: "none",
                  },
                  grid: {
                    display: "none",
                  },
                  tickLabels: { fill: "none" },
                }}
              />

              <VictoryLine
                name="line2"
                labels={val => {
                  return +val.index ===
                    serverData.response.socialsCreatedHistory.length - 1
                    ? `${val.datum.y}\nUnique Visitors`
                    : null
                }}
                style={{
                  labels: {
                    fontSize: 8,
                    fontWeight: "700",
                    fontFamily: "Space Mono, monospace",
                    fill: "#6633FF",
                    padding: 5,
                    transform:
                      socialsCreated?.[socialsCreated?.length - 1]?.x >= 1200
                        ? "translate(-40, 5)"
                        : "translate(40, 5)",
                  },
                  data: {
                    strokeWidth: "3px",
                    stroke: "#6633FF",
                  },
                }}
                data={totalVisitors}
                interpolation="basis"
              />

              <VictoryAxis
                dependentAxis
                style={{
                  grid: {
                    display: "none",
                  },
                  ticks: { padding: 10 },
                  tickLabels: {
                    fontSize: 8,
                    fontWeight: "700",
                    fontFamily: "Space Mono, monospace",
                    fill: "#6633FF",
                  },
                  axis: {
                    strokeWidth: "6px",
                    stroke: "#6633FF",
                    display: "none",
                  },
                }}
                // tickFormat={val => Math.round(val)}
                domain={[
                  0,
                  totalVisitors?.[totalVisitors?.length - 1]?.y + 350,
                ]}
              />

              <VictoryScatter
                style={{ data: { fill: "#6633FF", zIndex: 10 } }}
                data={[
                  totalVisitors?.[totalVisitors?.length - 1] || { x: 0, y: 0 },
                ]}
                name="scatter2"
              />
            </VictoryChart>
          </div>
        </div>
      </div>

      <div className="funnel-section">
        <div className="h2Text">Our funnel </div>

        {funnelData.length &&
          funnelData.map((val, ind) => (
            <div className="funnel-container" key={`${val} ${ind}`}>
              <div className="funnel-title body-txt">{val.title}</div>

              <div className="info-icon">
                <Popover content={val.popoverText} styleClass="black" />

                <InfoIcon />
              </div>

              <div className="funnel-icon">
                <FunnelIcon color={val.funnelColor} />

                <div className="funnel-value numbers">{val.count}</div>
              </div>
            </div>
          ))}
      </div>

      <VisitorDistribution
        browsers={response.browsers}
        countries={response.countries}
        os={response.os}
      />

      {/* <div className="shoutOut-section">
        <div className="h2Text">Launch day love!</div>

        <div className="shoutout-embed" data-wall="ThursdayByFolly"></div>
      </div> */}

      {/* {isBefore28thOctober() && (
        <FloatingBannerContainer>
          <CountdownTimer />
        </FloatingBannerContainer>
      )} */}

      <ColorSelector noCursor clickHandler={() => null} />

      <Footer />
    </>
  )
}

export default StatsPage

const getData = () => {
  const formattedData = {
    socialsCreatedHistory: [],
    uniqueVisitorsHistory: [],
    funnelData: [],
    uniqueVisitors: null,
    socialsCreated: 0,
    browsers: [],
    countries: [],
    os: [],
  }

  formattedData.socialsCreatedHistory = jsonData?.socialsCreatedHistory.map(
    val => ({
      x: val.time,
      y: val.value,
    })
  )
  formattedData.uniqueVisitorsHistory = jsonData.uniqueVisitorsHistory.map(
    val => ({
      x: val.time,
      y: val.value,
    })
  )

  const os = {
    Windows: 0,
    macOS: 0,
    Android: 0,
    iOS: 0,
    Others: 0,
  }

  let osTotal = 0

  jsonData?.operatingSystems?.values?.forEach((val, ind) => {
    const count = jsonData?.operatingSystems?.ratios?.[ind]
    osTotal += count

    if (val === "Mac") {
      os.macOS += count
    } else if (val === "Android") {
      os.Android += count
    } else if (val === "Apple iPhone") {
      os.iOS += count
    } else if (val === "Windows") {
      os.Windows += count
    } else {
      os.Others += count
    }
  })

  formattedData.browsers = normalizeDataInPercent({
    data: jsonData?.browsers,
  })

  formattedData.countries = normalizeDataInPercent({
    data: jsonData?.countries,
    isCountries: true,
  })

  formattedData.os =
    Object.entries(os)?.map((val, ind) => {
      return {
        x: val[0],
        y: Math.round(getPercent({ current: val[1], total: osTotal })),
      }
    }) || []

  const {
    uniqueVisitors,
    socialsCreated,
    socialsStarted,
    mixersPlayed,
    lovedIt,
  } = jsonData

  formattedData.uniqueVisitors = jsonData?.uniqueVisitors
  formattedData.socialsCreated = jsonData?.socialsCreated
  formattedData.funnelData = [
    uniqueVisitors,
    socialsCreated,
    socialsStarted,
    mixersPlayed,
    lovedIt,
  ]

  return {
    response: formattedData,
  }
}
